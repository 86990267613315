<template>
  <div class="">
    <item-form ref="form" action="Update" v-model="formData" @formSubmitted="updateItem" />
  </div>
</template>

  <script>
import ItemForm from "@/views/components/item/ItemForm.vue";
import ItemDescriptionService from "@/services/ItemDescriptionService";

export default {
  name: "UpdateOffer",
  components: {
    ItemForm
  },
  data() {
    return {
      ItemDescriptionService: new ItemDescriptionService(),
      id: this.$route.params.id,
      formData: {
      },
    }
  },
  methods: {
    updateItem() {
      this.$refs.form.loading = true;
      const send = { ...this.formData };
      delete send.category;
      this.ItemDescriptionService.update(this.formData.id, send).then(res => {
        this.$router.push({ name: 'all-items' })
      }).catch(err => {
      }).finally(() => {
        this.$refs.form.loading = false;
      })
    },
    mappingData(data) {
      this.$refs.form.$refs.itemInput.inputValue = data.item.name;
      this.$refs.form.$refs.descriptionInput.inputValue = data.description.content;
      return {
        id: data.id,
        item_id: data.item.id,
        low_price: data.low_price,
        high_price: data.high_price,
        description_id: data.description.id,
        reference_code_id: data.item.reference_code_id,
        category_id: data.item.product_category.id,
        additional_option: data.additional_option != "No",
        with_glass_details: data.item.with_glass_details,
        arabic_name: data.item.arabic_name
      }
    },
    getCustomer() {
      this.ItemDescriptionService.show(this.id).then(res => {
        this.formData = this.mappingData(res);
      }).catch(err => { }).finally(() => { })
    }
  },
  mounted() {
    this.getCustomer()
  }
}
</script>

  <style scoped>
  </style>
